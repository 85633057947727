<template>
    <div class="learn-more">
        <div class="a-container">
            <a-picture :link="background" class="map-bg" />
            <h2 class="title">
                {{ mainTitle }}
            </h2>
            <div class="stats">
                <div
                    v-for="(item, index) in stats"
                    :key="`learnMoreStats_${index}`"
                    class="item"
                >
                    <span class="stat">{{ item.stat }}</span>
                    <p class="title">
                        {{ item.title }}
                    </p>
                    <p class="description">
                        {{ item.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint';

export default {
    name: 'SLearnMore',
    components: {
        APicture,
    },
    mixins: [breakpoint],
    props: {
        background: {
            type: String,
            default: '@b675430435906d8c081c730f7a097479',
        },
        mainTitle: {
            type: String,
            default: null,
        },
        stats: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>

<style lang="postcss" scoped>
.learn-more {
    position: relative;
    background: linear-gradient(180deg, rgba(38, 104, 197, 0) 0%, rgba(38, 104, 197, 0.05) 100%);
    text-align: center;
    color: var(--av-nav-primary);
    padding: 48px 0;
    @media (--viewport-tablet) {
        padding-top: 0;
    }
    .map-bg {
        position: absolute;
        top: 714px;
        width: 1064px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        @media (--viewport-tablet) {
            top: 580px;
        }
        @media (--viewport-desktop) {
            top: 390px;
        }
        @media (--viewport-desktop-wide) {
            top: 144px;
            max-width: 90%;
            width: 100%;
        }
    }
    .title {
        @mixin hero-accent;
        margin-bottom: 32px;
        position: relative;
        z-index: 3;
    }
    .stats {
        position: relative;
        z-index: 3;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 48px;
        @media (--viewport-desktop) {
            flex-flow: row nowrap;
        }
        .item {
            position: relative;
            @mixin colls 6;
            padding: 24px 0px;
            @media (--viewport-tablet) {
                padding: 24px 40px;
            }
             @media (--viewport-desktop) {
                @mixin colls 3;
                display: flex;
                flex-flow: column;
                justify-content: center;
                padding: 40px 0 0;
            }
            @media (--viewport-desktop-wide) {
                padding: 40px 24px 16px;
            }
            @media (--viewport-desktop-large) {
                padding: 40px 24px 38px;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                inset-inline-end: -8px;
                width: 1px;
                height: 100%;
                background: var(--av-brand-secondary-light);
            }
            &:nth-child(2n) {
                &::before {
                    display: none;
                    @media (--viewport-desktop) {
                        display: block;
                    }
                }
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
            .stat {
                display: block;
                @mixin large-accent;
                color: var(--av-nav-secondary);
                margin-bottom: 4px;
            }
            .title {
                @mixin paragraph-accent;
                margin-bottom: 8px;
            }
            .description {
                @mixin body;
                color: var(--av-fixed-light);
            }
        }
    }
}
</style>
