<template>
    <div class="certification-cards">
        <div class="a-container">
            <div class="wrapper">
                <div class="intro">
                    <h4 class="title">
                        {{ title }}
                    </h4>
                    <p class="lead">
                        {{ lead }}
                    </p>
                </div>
                <div class="cards">
                    <div
                        v-for="(card, index) in cards"
                        :key="`certification_cards_${index}`"
                        class="item"
                    >
                        <a-icon
                            class="icon"
                            :number="card.icon"
                            :icon-variant="card?.iconVariant"
                        />
                        <span class="title">{{ card.title }}</span>
                        <p class="description">
                            {{ card.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AIcon from '@core/components/icon/icon.vue';

export default {
    name: 'SAcademyCertificationCards',
    components: {
        AIcon,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        lead: {
            type: String,
            default: '',
        },
        cards: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>

<style lang="postcss" scoped>
.certification-cards {
    padding: 48px 0;
    position: relative;
    z-index: 3;
    @media (--viewport-desktop-wide) {
        padding: 80px 0;
    }
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;
        gap: 16px;
        .intro {
            @mixin colls 12;
            position: relative;
            border-radius: 8px;
            color: var(--av-inversed-primary);
            background: var(--av-nav-primary);
            text-align: center;
            padding: 24px 32px;
            overflow: hidden;
            @media (--viewport-tablet) {
                padding: 40px 32px;
            }
            @media (--viewport-desktop) {
                text-align: start;
                padding: 64px 32px;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 4;
                height: auto;
                padding: 72px 24px 0;
            }
            &:after {
                content: '';
                background: linear-gradient(180deg, rgba(10, 23, 92, 0) 0%, #1737E2 100%);
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
            }
            .title {
                @mixin title-accent;
                margin-bottom: 8px;
                @media (--viewport-tablet) {
                    @mixin display-accent;
                }
                @media (--viewport-desktop-wide) {
                    margin-bottom: 40px;
                }
            }
            .lead {
                @mixin paragraph;
                @media (--viewport-tablet) {
                    @mixin title;
                }
            }
            .title, .lead {
                position: relative;
                z-index: 3;
            }
        }
        .cards {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: 16px;
            @media (--viewport-desktop-wide) {
                @mixin colls 8;
            }
            .item {
                background: var(--av-solid-brand-accent);
                border: 1px solid var(--av-brand-secondary-light);
                border-radius: 8px;
                padding: 48px 16px;
                width: 100%;
                @media (--viewport-tablet) {
                    max-width: calc(50% - 8px);
                    padding: 48px 40px;
                }
                .icon {
                    max-width: 48px;
                    max-height: 48px;
                    margin-bottom: 16px;
                }
                .title {
                    @mixin title-accent;
                    display: block;
                    color: var(--av-nav-primary);
                    margin-bottom: 24px;
                }
                .lead {
                    @mixin lead;
                    color: var(--av-fixed-light);
                }
            }
        }
    }
}
</style>
