<template>
    <main class="page-container">
        <div class="ribbon-placeholder" :class="{ 'crit-hidden': isMounted || !showRibbon }">
            <div v-loading="true" class="loading" el-loading-size="24" />
        </div>

        <div v-if="isDraft" class="draft-warning-banner">
            <a-glyph name="attention2" />
            <span>THIS IS A DRAFT LINK: DO NOT publish or share externally.</span>
            <a-glyph name="attention2" />
        </div>

        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />

        <s-ribbon-container :class="{ 'crit-hidden': !isMounted || isLocaleSelectorShown || !showRibbon }" :ribbons="ribbons" />

        <div class="page">
            <component
                v-bind="slice.data"
                :is="slice.name"
                v-for="(slice, position) in slices"
                :key="slice.id || `${slice.name}-${position}`"
            />
        </div>

        <template v-if="showPopup && isMounted">
            <s-popup-container :popups="popups" />
        </template>

        <s-chat v-if="!page.settings?.hide_chat" :locale="locale" />

        <s-unsupported-browsers />
    </main>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { merge } from 'lodash';
import Vue from 'vue';
import analytics from '@core/mixins/analytics.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SUnsupportedBrowsers from '@core/slices/pages/unsupported-browsers/unsupported-browsers.vue';
import SChat from '@core/slices/shared/chat/chat.vue';
import SPopupContainer from '@core/slices/shared/popup/popup-container.vue';
import SRibbonContainer from '@core/slices/shared/ribbon/ribbon-container.vue';
import { resolvePageBodySlices } from '@utils/resolve-nested-slices';

import '@core/styles/sections/pages.pcss';

export default {
    name: 'PagesComponent',

    components: {
        SChat,
        SPopupContainer,
        SRibbonContainer,
        SUnsupportedBrowsers,
        SLocaleSelectorRibbon,
    },

    directives: {
        Loading,
    },

    mixins: [analytics, localeRibbon],

    computed: {
        page() {
            return this.$store.state.pages.page;
        },
        isDraft() {
            return !this.page.is_published;
        },
        slices() {
            const page = this.page.body || [];
            return page.map((item) => {
                const translations = item.data?.translations || {};
                const data = merge({}, item.data, translations);
                return { ...item, data };
            });
        },
        ribbons() {
            return this.$store.state.ribbon.data;
        },
        showRibbon() {
            return Boolean(this.ribbons.length);
        },
        popups() {
            return this.$store.state.popup.data;
        },
        showPopup() {
            return Boolean(this.popups.length);
        },
    },

    beforeCreate() {
        resolvePageBodySlices(this.$store.state.pages.page.body).forEach((fullname) => {
            const sectionName = fullname.startsWith('s-trueimage') ? 'trueimage' : 'pages';
            const sliceName = fullname.replace(/^s-/, '');
            Vue.component(fullname, () => import(`@core/slices/${sectionName}/${sliceName}/${sliceName}.vue`));
        });
    },
};
</script>

<style lang="postcss" scoped>
.ribbon-placeholder {
    min-height: 80px;

    @media (--viewport-tablet) {
        min-height: 56px;
    }
}

.ribbon-placeholder .loading {
    height: 100%;
}

.page {
    position: relative;
}
</style>
