<template>
    <div class="courses-section">
        <div class="a-container">
            <nav v-if="!isMobile" class="categories-wrapper">
                <ul>
                    <li
                        v-for="category in categories"
                        :key="category.title"
                        :class="{active: activeCategory === category.title}"
                        @click="activeCategory = category.title"
                    >
                        {{ category.title }}
                    </li>
                </ul>
            </nav>
            <el-select v-else v-model="activeCategory" class="mobile-nav">
                <el-option
                    v-for="category in categories"
                    :key="category.title"
                    :value="category.title"
                    :label="category.title"
                />
            </el-select>
            <div class="cards">
                <div
                    v-for="course in getCoursesByCategory(activeCategory)"
                    :key="course.id"
                    class="item"
                >
                    <a-dynamic-link
                        class="image-wrapper"
                        :to="`/catalogue/${course.slug}`"
                    >
                        <a-picture
                            :link="course?.imageUrl || fallBackImage"
                            :title="course.name"
                            fit="cover"
                            class="image"
                        />
                    </a-dynamic-link>
                    <div class="content">
                        <a-link
                            class="title"
                            :to="`/catalogue/${course.slug}`"
                            :text="course.name"
                        />
                        <p class="description">
                            {{ course.description }}
                        </p>
                        <div class="actions">
                            <a-link
                                class="view-more"
                                v-bind="viewMoreLink"
                                :to="`/catalogue/${course.slug}/`"
                                :size="viewMoreLink.size || 'paragraph'"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <a-button
                v-if="exploreAllButton"
                class="explore-all"
                v-bind="exploreAllButton"
            />
        </div>
    </div>
</template>

<script>
import { orderBy } from 'lodash';
import { mapState } from 'vuex';
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint';

export default {
    name: 'SAcademyCourses',
    components: {
        ALink,
        AButton,
        APicture,
        ADynamicLink,
        ElSelect: () => import('@uikit/ui-kit/packages/select/index.js'),
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },
    mixins: [breakpoint],
    props: {
        translations: {
            type: Object,
            default() {},
        },
        viewMoreLink: {
            type: Object,
            default() {},
        },
        fallBackImage: {
            type: String,
            default: '@68d92c949314272a1db7d4275d34192f',
        },
        exploreAllButton: {
            type: Object,
            default() {},
        },
    },
    data() {
        return {
            categories: [],
            filteredList: [],
            activeCategory: '',
        };
    },
    computed: {
        ...mapState({
            courses: (state) => state.academy.courses,
        }),

        catalogueOrder() {
            return this.$store.state.slices.items?.['s-academy-acronis-com']?.settings?.catalogue || [];
        },
    },

    mounted() {
        this.getCategories();
        this.activeCategory = this.translation('Featured');
    },
    methods: {
        translation(text) {
            return this.translations?.[text] || text;
        },

        getCategories() {
            const categoryMap = {};

            this.courses.forEach((course) => {
                course.showcases.forEach((showcase) => {
                    const order = showcase.order.toString();
                    const title = this.translation(showcase.name);

                    if (!categoryMap[order]) {
                        categoryMap[order] = { order, title };
                    }
                });
            });

            categoryMap['0'] = { order: '0', title: this.translation('Featured') };
            this.categories = orderBy(Object.values(categoryMap), 'order', 'asc');
        },

        getCoursesByCategory(categoryName) {
            return categoryName === this.translation('Featured')
                ? this.getSortedCourses().slice(0, 3)
                : this.getSortedCourses()
                    .filter((course) => course.showcases
                        .some((showcase) => showcase.name.replace('MSP ', '') === categoryName))
                    .slice(0, 3);
        },

        getSortedCourses() {
            return orderBy(
                this.catalogueOrder
                    .map((orderedItem) => {
                        const course = this.courses.find((c) => c.id === orderedItem.id);
                        return course ? { ...course, order: orderedItem.order || 999 } : null;
                    })
                    .filter(Boolean),
                'order',
                'desc',
            );
        },
    },
};
</script>

<style lang="postcss" scoped>
.courses-section {
    background: linear-gradient(180deg, rgba(38, 104, 197, 0.25) 0%, rgba(38, 104, 197, 0) 100%);
    padding-bottom: 44px;
    .categories-wrapper {
        position: relative;
        height: 64px;
        top: -33px;
        border: 2px solid var(--av-inversed-primary);
        background: radial-gradient(35.07% 719.53% at 50% -397.66%, #002FFF 0%, #00204D 100%);
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 8px 32px;
        @media (--viewport-tablet) {
            justify-content: center;
        }
        @media (--viewport-desktop) {
            justify-content: flex-start;
        }
        ul {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--av-inversed-primary);
            li {
                @mixin paragraph-accent;
                padding: 12px 24px;
                cursor: pointer;
                &.active {
                    border-radius: 14px;
                    background: var(--av-inversed-primary);
                    color: rgba(11, 92, 213, 1);
                    box-shadow: 0px 4px 10px 0px rgba(10, 19, 9, 0.12);
                }
            }
        }
    }
    .mobile-nav {
        margin: 24px 0;
    }
    .cards {
        margin-top: -8px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;
        .item {
            @mixin colls 12;
            display: flex;
            flex-flow: column;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: var(--av-shadow-small);
            border: 1px solid var(--av-brand-secondary-light);
            background: var(--av-inversed-primary);
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
            @media (--viewport-tablet) {
                @mixin colls 8;
                margin: 0 auto 16px;
            }
            @media (--viewport-desktop) {
                @mixin colls 4;
                margin: 0;
            }
            .image {
                &:deep(img) {
                    aspect-ratio: 16 / 9;
                    min-width: 100%;
                }
            }
            .content {
                display: flex;
                flex-flow: column;
                flex-grow: 1;
                padding: 24px;
                @media (--viewport-tablet) {
                    padding: 24px 16px;
                }
                @media (--viewport-desktop) {
                    padding: 24px;
                }
                .title {
                    &:deep(.a-link__content) {
                        @mixin nav-title-accent;
                    }
                }
                .description {
                    @mixin paragraph;
                    color: var(--av-fixed-light);
                    margin-bottom: 24px;
                    @media (--viewport-desktop) {
                        margin-bottom: 12px;
                    }
                }
                .actions {
                    border-top: 1px solid var(--av-brand-secondary-light);
                    padding-top: 24px;
                    margin-top: auto;
                    .view-more {
                        font-weight: 700;
                    }
                }
            }
        }
    }
    .explore-all {
        margin: 40px auto 0;
        display: block;
        width: 312px;
        text-align: center;
    }
}
</style>
