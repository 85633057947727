<template>
    <div :id="id" class="faq-section">
        <div class="a-container">
            <div class="wrapper">
                <div class="intro">
                    <h6 class="title">
                        {{ title }}
                    </h6>
                </div>
                <div class="questions">
                    <a-accordion class="faq-accordion" one-only-mode>
                        <a-accordion-item
                            v-for="(item, index) in questions"
                            :key="`faq_section_question_${index}`"
                            class="faq-accordion-item"
                            :title="item.question"
                        >
                            <a-dangerous-html :content="item.answer" tag="div" />
                        </a-accordion-item>
                    </a-accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';

export default {
    name: 'SAcademyFaq',
    components: {
        ADangerousHtml,
        AAccordion,
        AAccordionItem,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        questions: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>

<style lang="postcss" scoped>
.faq-section {
    padding: 48px 0;
    @media(--viewport-desktop) {
        padding: 0;
    }
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .intro {
            @mixin colls 12;
            padding: 64px 16px;
            border-radius: 8px;
            background: rgba(177, 237, 29, 1);
            margin-bottom: 16px;
            @media (--viewport-tablet) {
                padding: 64px 32px;
            }
            @media (--viewport-desktop) {
                @mixin colls 5;
                border-radius: 8px 8px 0 0;
                margin-bottom: 0;
                padding: 64px;
                padding-inline-end: 74px;
            }
            .title {
                @mixin display-accent;
                text-align: center;
                color: var(--av-nav-primary);
                @media (--viewport-tablet) {
                    @mixin large-accent;
                    text-align: start;
                }
            }
        }
        .questions {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 7;
                padding: 52px 0;
            }
            @media (--viewport-desktop-large) {
                padding: 52px 0 52px 110px;
            }
            .faq-accordion-item {
                margin: 0;
                &:deep(.title) {
                    @mixin lead-accent;
                    align-items: center;
                    color: var(--av-brand-primary);
                    padding: 40px 0 12px;
                    @media (--viewport-desktop) {
                        @mixin title-accent;
                    }
                }
                &:first-child {
                    border-top: none;
                    &:deep(.title) {
                        @media (--viewport-desktop) {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
